<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一人一档</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">姓名:</span>
              <el-input
                v-model="Name"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idCard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号码" class="searchboxItem ci-full">
              <span class="itemLabel">手机号码:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号码"
                clearable
              />
            </div>
            <div class="df" style="padding-right:20px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="是否合格"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.eligible ? "是" : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="头像"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="img-el-upload">
                    <el-image
                      style="width:40px;height:50px;margin: 0 5px"
                      :src="scope.row.headPathUrl"
                      fit="contain"
                      v-show="scope.row.headPathUrl"
                    ></el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="认证照片"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div style="display: flex">
                    <el-image
                      v-for="(item, index) in scope.row.approveFaces"
                      :key="index"
                      class="theImgno"
                      style="width: 40px; height: 50px; margin: 0 5px"
                      :src="item.url"
                      :preview-src-list="[item.url]"
                    >
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 15px"
                    @click="
                      lookupTrainDocument(scope.row.projectId, scope.row.userId)
                    "
                    :disabled="scope.row.certState == '0'"
                    >档案详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="查看学员档案"
      :visible.sync="dialogDownload"
      top="2%"
      width="50%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 700px"></div>
    </el-dialog>
    <el-dialog
      title="学时证明模板选择"
      :visible.sync="templateSelection"
      top="2%"
      width="50%"
      center
    >
      <div>
        <el-radio-group v-model="radio">
          <el-popover
            placement="bottom"
            title="学时证明模板一"
            width="450"
            trigger="hover"
          >
            <img
              src="../../assets/templateOne.png"
              alt=""
              style="width: 100%; height: 100%"
            />
            <button
              @click="radio = '1'"
              style="background-color: transparent;border: none;"
              slot="reference"
            >
              <el-radio label="1">学时证明模板一</el-radio>
            </button>
          </el-popover>
          <el-popover
            placement="bottom"
            title="学时证明模板二"
            width="450"
            trigger="hover"
          >
            <img
              src="../../assets/templateTwo.png"
              alt=""
              style="width: 100%; height: 100%"
            />
            <button
              @click="radio = '2'"
              style="background-color: transparent;border: none;"
              slot="reference"
            >
              <el-radio label="2">学时证明模板二</el-radio>
            </button>
          </el-popover>
        </el-radio-group>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "OnepersonStudentFiles",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      Name: "",
      idCard: "",
      mobile: "",
      dialogDownload: false,
      templateSelection: false,
      radio: "1",
      projectId: "",
      userId: "",
      hasCache:true
    };
  },
  created() {
     this.hasCache = this.$route.query.hasCache
    //每天首次进入的时候弹提示--------- 开始
    let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
    //获取当前时间
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let nowDate = year + "-" + month + "-" + day;
    console.log(localStorage.getItem(userId));
    if (!localStorage.getItem(userId)) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = {};
          userKey["StudentFileQuery"] = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    } else if (
      JSON.parse(localStorage.getItem(userId)).StudentFileQuery == undefined ||
      JSON.parse(localStorage.getItem(userId)).StudentFileQuery < nowDate
    ) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = JSON.parse(localStorage.getItem(userId));
          userKey.StudentFileQuery = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    }
    //每天首次进入的时候弹提示-------- 结束
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
      };
      if (this.Name) {
        params.userName = this.Name;
      }
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      this.doFetch({
        url: "/eighteenEnterprise/enterpriseUser/userPageList",
        params,
        pageNum,
      });
    },
    async oneKeyGet(tableData) {
      tableData.forEach((el) => {
        this.tableData.push(Object.assign({}, el));
      });
      for (let i in tableData) {
        tableData[i]["approveFaces"] = [];
        let params = {
          projectCourseId: tableData[i].projectCourseId,
          projectId: tableData[i].projectId,
          userId: tableData[i].userId,
        };
        await this.$post("/run/project/viewImage", params, 3000, false).then(
          (res) => {
            if (res.status == 0) {
              tableData[i].approveFaces = [...res.data];
            }
          }
        );
      }
      this.tableData = tableData;
      this.$forceUpdate();
    },
    //  照片单独加载
    handleListDataBack(tableData) {
      this.oneKeyGet(tableData);
    },
    /* 查看档案 */
    lookupTrainDocument(projectId, userId) {
      this.projectId = projectId;
      this.userId = userId;
      let hasCache = this.hasCache === 'true'
      console.log(hasCache);
      if (hasCache) {
        let parmar = {
          projectId: projectId,
          userId: userId,
        };
        this.$post(
          "/eighteenEnterprise/enterpriseUser/downloadUserDocument",
          parmar
        ).then((res) => {
          if (res.status == 0) {
            this.dialogDownload = true;
            this.$nextTick(() => {
              pdf.embed(res.data, "#pdf-content");
            });
          } else {
            that.$message.warning({ message: res.message, duration: 1500 });
          }
        });
      } else {
        this.templateSelection = true;
      }
    },
    confirmDocsDialog() {
      let parmar = {
        projectId: this.projectId,
        userId: this.userId,
        modeType: this.radio,
      };
      this.$post(
        "/eighteenEnterprise/enterpriseUser/downloadUserDocument",
        parmar
      ).then((res) => {
        if (res.status == 0) {
          this.templateSelection = false;
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDocsDialog() {
      this.templateSelection = false;
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.el-radio-group {
  display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>
